import "./src/style.css";
import "./src/player_files/bootstrap.css";
import "./src/player_files/talk-plugin.css";
import "./src/player_files/keyboard.css";

import "@riotjs/hot-reload";
import { mount } from "riot";


import * as riot from 'riot'

//import App from './app.riot'
// import  './src/player_files/jquery-3.5.1.min.js'

 
 import prin from './src/principalApp.riot'
// import TaminSDK from './src/tamkin-sdk-only-player.riot'



//riot.register('tamkin-sdk', TaminSDK)
riot.register('tamkin-player-sdk', prin)



 //riot.mount('my-test')
 riot.mount('main-player')
//  riot.mount('tamkin-sdk')

 riot.mount('Side-Menu')
riot.mount('tamkin-player-sdk')

